// Due to aggressive adblockers we need to make this import under a try / catch
// import { env } from '$env/dynamic/public';
// import { logError } from '$lib/logError';
import { clientInit } from '@jill64/sentry-sveltekit-cloudflare';
import type { HandleClientError } from '@sveltejs/kit';

import { dev } from '$app/environment';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import { getErrorMessage } from '$lib/getErrorMessage';

const onError = clientInit(PUBLIC_SENTRY_DSN, {
	sentryOptions: {
		environment: 'production'
	},
	enableInDevMode: false
});

export const handleError: HandleClientError = onError((error) => {
	const message = dev
		? getErrorMessage(error)
		: 'Something went wrong on the client. Please try again later.';

	return {
		message
	};
});
